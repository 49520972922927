import URL from '@/views/pages/request';
import axios from 'axios';

class clientService {
    
    constructor(){
        this.config = {headers: {
            Accept: 'application/json',
          }};
        this.clients  = [] 
    }

    async getClients(){
        const {data} = await axios.get(URL.CLIENT_LIST, this.config)
        return data
    }
}

export default new clientService()